import container from "../../libs/container/container";

const amdHomeVideoModule = (function () {
    const name = 'home__video-module';

    const view = container.getBaseView(name);

    view.privateVars = {

    }

    view.startup = function () {

    };

    view.bind = function () {

    };

    view.methods = {

    }

    return {
        init: function (options) {
            view.init(options);
        }
    }
})();

export default amdHomeVideoModule;