import container from "../../libs/container/container";
import moduleHomeVideo from '../../module/home/video';
import moduleHomeMediaSlider from '../../module/home/media-slider';

const amdHomePage = (function () {
    const name = 'home';

    const view = container.getBaseView(name);

    view.privateVars = {
        itemsKeys: [],
        items: new Map(),
        canNavigateAction: 0
    }

    view.startup = function () {
        if(view.publicVars.moduleVideoConfig) {
            view.privateVars.itemsKeys.push('home__video');

            view.privateVars.items.set('home__video', {
                init: false,
                module: moduleHomeVideo,
                config: view.publicVars.moduleVideoConfig,
                callbacks: {}
            });
        }

        if(view.publicVars.moduleMediaSliderConfig) {
            view.privateVars.itemsKeys.push('home__media-slider');

            view.privateVars.items.set('home__media-slider', {
                init: false,
                module: moduleHomeMediaSlider,
                config: view.publicVars.moduleMediaSliderConfig,
                callbacks: {}
            });
        }

        view.methods.handleCurrentScrollPosition();
    };

    view.bind = function () {

        let ticking = false;

        window.addEventListener('scroll', function () {
            if (view.privateVars.canNavigateAction === 0) {
                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        view.methods.handleCurrentScrollPosition();
                        ticking = false;
                    });
                }
                ticking = true;
            }
        });
    };

    view.methods = {
        handleCurrentScrollPosition: () => {

            const systemCurrentScroll = document.documentElement.scrollTop || window.pageYOffset;

            for (let [key, value] of view.privateVars.items) {

                let cssNavigateTarget = document.querySelector(view.publicVars.cssId.layout.navigateTargetId + '-' + key);

                let targetPos = {
                    top: cssNavigateTarget.getBoundingClientRect().top + window.scrollY,
                    left: cssNavigateTarget.getBoundingClientRect().left + window.scrollX
                };

                view.methods.handleModuleInit(systemCurrentScroll, key, targetPos);
            }
        },

        handleModuleInit: (currentScroll, key, targetPos) => {

            if (view.privateVars.items.get(key).module && view.privateVars.items.get(key).init === false) {
                const rect = document.querySelector(view.publicVars.cssId.layout.navigateTargetId + '-' + key).getBoundingClientRect();
                const scrollPlusWindowHeight = document.documentElement.clientHeight + currentScroll;

                let specialModifier = 0;
                const cssHeader = document.querySelector(view.publicVars.cssId.headerCls);

                if(cssHeader) {
                    specialModifier = cssHeader.offsetHeight;
                }

                if ((targetPos.top - 200) < scrollPlusWindowHeight &&
                    (scrollPlusWindowHeight < (targetPos.top + rect.height) || currentScroll < (targetPos.top + rect.height + specialModifier))) {

                    view.methods.fireModuleStart(key);

                }
            }

        },

        fireModuleStart: (key) => {
            if (view.privateVars.items.get(key).module && view.privateVars.items.get(key).init === false) {

                view.privateVars.items.get(key).init = true;
                view.privateVars.items.get(key).module.init(
                    view.privateVars.items.get(key).config,
                    view.privateVars.items.get(key).callbacks
                );
            }
        },
    }

    return {
        init: function (options) {
            view.init(options);
        }
    }
})();

amdHomePage.init(window.extHomePageConfig);