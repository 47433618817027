import container from "../../libs/container/container";
import carouselInfiniteCutFactory from "../../libs/slider/carousel-infinite-cut";

const amdHomeMediaSliderModule = (function () {
    const name = 'home__media-slider';

    const view = container.getBaseView(name);

    view.privateVars = {

    }

    view.startup = function () {
        const carouselInfiniteCut = carouselInfiniteCutFactory.create();
        carouselInfiniteCut.init(view.publicVars.carouselCutSetup);
    };

    view.bind = function () {

    };

    view.methods = {

    }

    return {
        init: function (options) {
            view.init(options);
        }
    }
})();

export default amdHomeMediaSliderModule;