import container from "../container/container";
import preloader from "../preloader/preloader";
import $ from 'jquery';
import jqueryTransit from "jquery.transit";

const slider = function (id) {

    let publicVars = {};

    const privateVars = {
        name: 'carousel-infinite-cut(' + id + ')',
        sliderCount: 0,

        slide: 0,
        sliderSets: 0,

        sliderCurrentSet: 1,
        sliderCurrentItemFirst: 1,
        sliderCurrentItemLast: 2,
        sliderItemsMap: {},
        elemDecorateCallback: null,

        touchStart: 0,
        touchDiff: 0,
        touchStartY: 0,
        touchDiffY: 0,

        slideStarted: false,
        endTrans: false,

        imgPreload: new Map()
    };


    const privateMethods = {
        prepareObj: function (options) {

            publicVars = container.performCopy(publicVars, options);

            preloader.init();

            privateMethods.bind();

            privateMethods.startup();


        },
        /*
         * preload images
         */
        preloadImages: function () {
            let currentFirst = privateVars.sliderCurrentItemFirst;

            let imgSrc, i;

            for (i = 0; i < 2 * (publicVars.perSetItems - 2); i++) {

                if (privateVars.sliderItemsMap[(privateVars.sliderCurrentItemLast + i)]) {

                    imgSrc = container.getData(privateVars.sliderItemsMap[(privateVars.sliderCurrentItemLast + i)].element[0], 'img.src');

                    $(publicVars.cssId.sliderItemBoxImgCls, privateVars.sliderItemsMap[(privateVars.sliderCurrentItemLast + i)].element).attr('src', imgSrc);

                    if (!privateVars.imgPreload.has(imgSrc)) {
                        privateVars.imgPreload.set(imgSrc, 1);
                        preloader.preloadSingleImage('system', imgSrc, function () {
                        });
                    }

                }

                if (currentFirst > 1) {
                    currentFirst = currentFirst - 1;
                } else {
                    currentFirst = privateVars.sliderCount;
                }

                if (privateVars.sliderItemsMap[(currentFirst - 1)]) {

                    imgSrc = container.getData(privateVars.sliderItemsMap[(currentFirst - 1)].element[0], 'img.src');

                    $(publicVars.cssId.sliderItemBoxImgCls, privateVars.sliderItemsMap[(currentFirst - 1)].element).attr('src', imgSrc);

                    if (!privateVars.imgPreload.has(imgSrc)) {
                        privateVars.imgPreload.set(imgSrc, 1);
                        preloader.preloadSingleImage('system', imgSrc, function () {
                        });
                    }

                }

            }
        },
        startup: function () {

            publicVars.perSetItems = privateMethods.getCurrentPerSetItemsNumber();
            container.msg(privateVars.name, 'calculated per set items: ' + publicVars.perSetItems);

            privateVars.sliderCount = $(publicVars.cssId.sliderItemCls).length;
            container.msg(privateVars.name,'items count: ' + privateVars.sliderCount);

            privateVars.sliderCurrentItemLast = publicVars.perSetItems;

            $(publicVars.cssId.sliderItemCls).each(function (key, value) {

                const cloned = $(this).clone(true, true);

                if (publicVars.options && publicVars.options.stripHref === 1) {
                    cloned.removeAttr('href');
                }

                //cloned.removeClass(publicVars.cssId.sliderActiveCls.substr(1));

                privateVars.sliderItemsMap[key] = {
                    element: cloned
                };

                if (publicVars.perSetItems < (key + 1)) {
                    $(this).remove();
                }

            });

            privateMethods.calculateSliderSets();
            privateMethods.handleSliderArrows();

            privateMethods.preloadImages();

            container.msg(privateVars.name, 'first item ' + privateVars.sliderCurrentItemFirst + ' last item ' + privateVars.sliderCurrentItemLast);

        },
        bind: function () {
            container.msg(privateVars.name, 'module started');

            //carousel arrow click
            $(publicVars.cssId.sliderArrowLeftId).click(function () {

                if (privateVars.slide === 0 && privateVars.sliderSets > 1) {

                    privateVars.slide = 1;

                    container.msg(privateVars.name, 'left arrow clicked');

                    privateMethods.moveSlider('left');
                }

            });

            //carousel arrow click
            $(publicVars.cssId.sliderArrowRightId).click(function () {

                if (privateVars.slide === 0 && privateVars.sliderSets > 1) {

                    privateVars.slide = 1;

                    container.msg(privateVars.name,'right arrow clicked');

                    privateMethods.moveSlider('right');
                }

            });

            $(window).resize(function () {
                privateMethods.recalculateSlider();
            });

            $(window).on("orientationchange", function () {
                privateMethods.recalculateSlider();
            });

            $(publicVars.cssId.sliderWrapperId).bind('touchstart', function (e) {
                //e.preventDefault();
                const touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
                return privateMethods.slideMouseDown(touch);
            });

            $(publicVars.cssId.sliderWrapperId).bind('touchmove', function (e) {
                /*if (Math.abs(privateVars.touchDiff) >= Math.abs(privateVars.touchDiffY)) {
                 e.preventDefault();
                 }*/
                const touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
                return privateMethods.slideMouseMove(touch, e);
            });

            $(publicVars.cssId.sliderWrapperId).bind('touchend', function (e) {
                //e.preventDefault();
                const touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
                return privateMethods.slideMouseUp(touch);
            });
        },

        slideMouseDown: function (e) {

            if (!privateVars.slideStarted && privateVars.slide === 0 && privateVars.sliderCount >= publicVars.perSetItems) {
                privateVars.touchStart = e.pageX;
                privateVars.touchStartY = e.pageY;

                privateVars.slideStarted = true;

            }

            //return false;

        },

        slideMouseMove: function (e, ev) {

            if (privateVars.slideStarted && !privateVars.endTrans && privateVars.slide === 0) {
                privateVars.touchDiff = privateVars.touchStart - e.pageX;
                privateVars.touchDiffY = privateVars.touchStartY - e.pageY;

                if (privateVars.touchDiff < -40) {

                    privateVars.slide = 1;
                    privateMethods.moveSlider('left');

                } else if (privateVars.touchDiff > 40) {

                    privateVars.slide = 1;
                    privateMethods.moveSlider('right');

                }

                if (Math.abs(privateVars.touchDiff) >= Math.abs(privateVars.touchDiffY)) {
                    ev.preventDefault();
                }
            }

            //return false;
        },

        slideMouseUp: function (e) {

            if (privateVars.slideStarted && privateVars.slide === 0) {
                privateVars.endTrans = true;
                if (privateVars.touchDiff < -40) {
                    privateVars.slide = 1;

                    privateMethods.moveSlider('left', false);
                } else if (privateVars.touchDiff > 40) {
                    privateVars.slide = 1;

                    privateMethods.moveSlider('right', false);
                } else {

                    privateVars.touchDiff = 0;
                    privateVars.touchStart = 0;

                    privateVars.touchDiffY = 0;
                    privateVars.touchStartY = 0;


                    privateVars.slideStarted = false;
                    privateVars.endTrans = false;
                }
            } else {

                privateVars.slideStarted = false;
                privateVars.endTrans = false;
            }
        },

        getCurrentPerSetItemsNumber: function () {
            if (publicVars.perSetItemsTab.length > 0) {

                let perSet = publicVars.perSetItems;
                let i;

                for (i = 0; i < publicVars.perSetItemsTab.length; i++) {
                    if (window.matchMedia('(min-width: ' + publicVars.perSetItemsTab[i]['min'] + 'px) and (max-width: ' + publicVars.perSetItemsTab[i]['max'] + 'px)').matches) {
                        perSet = publicVars.perSetItemsTab[i]['perSet'];
                    }
                }

                return perSet;

            } else {
                return publicVars.perSetItems;
            }
        },

        calculateSliderSets: function () {
            if (privateVars.sliderCount > 0 && (publicVars.perSetItems) - 1 === 0) {
                privateVars.sliderSets = Math.ceil(privateVars.sliderCount / (publicVars.perSetItems));
            } else if (privateVars.sliderCount > 0) {
                privateVars.sliderSets = Math.ceil(privateVars.sliderCount / (publicVars.perSetItems - 1));
            } else {
                privateVars.sliderSets = 0;
            }

            if (privateVars.sliderSets > 1) {
                $(publicVars.cssId.sliderId).removeClass(publicVars.cssId.isSliderFullCls.substr(1));
            } else {
                $(publicVars.cssId.sliderId).addClass(publicVars.cssId.isSliderFullCls.substr(1));
            }
        },

        recalculateSlider: function () {
            const newNumberOfItems = privateMethods.getCurrentPerSetItemsNumber();

            if (publicVars.perSetItems !== newNumberOfItems) {
                publicVars.perSetItems = newNumberOfItems;

                privateMethods.calculateSliderSets();

                if ((privateVars.sliderCurrentItemFirst + publicVars.perSetItems - 1) <= privateVars.sliderCount) {
                    privateVars.sliderCurrentItemLast = privateVars.sliderCurrentItemFirst + publicVars.perSetItems - 1;
                } else {

                    if (privateVars.sliderCount < publicVars.perSetItems) {

                        if (privateVars.sliderCurrentItemFirst > 1) {
                            privateVars.sliderCurrentItemLast = privateVars.sliderCurrentItemFirst - 1;
                        } else {
                            privateVars.sliderCurrentItemLast = privateVars.sliderCount;
                        }

                    } else {
                        //var rest = privateVars.sliderCount - privateVars.sliderCurrentItemFirst;
                        privateVars.sliderCurrentItemLast = publicVars.perSetItems + privateVars.sliderCurrentItemFirst - privateVars.sliderCount - 1;
                    }
                }

                privateMethods.handleSliderArrows();

                $(publicVars.cssId.sliderSetCls).html('');

                privateMethods.putProperData('recalculate', privateVars.sliderCurrentItemFirst, privateVars.sliderCurrentItemLast);

                container.msg(privateVars.name, 'recalculate - first item ' + privateVars.sliderCurrentItemFirst + ' last item ' + privateVars.sliderCurrentItemLast);
                container.msg(privateVars.name, 'recalculate - current set ' + privateVars.sliderCurrentSet);
                container.msg(privateVars.name, 'recalculate - per set ' + publicVars.perSetItems);
            }
        },

        handleSliderArrows: function () {
            if (privateVars.sliderSets < 2) {
                if (publicVars.btnInactive === 1) {
                    $(publicVars.cssId.sliderArrowLeftId).removeClass(publicVars.cssId.sliderArrowStripCls.substr(1));
                    $(publicVars.cssId.sliderArrowRightId).removeClass(publicVars.cssId.sliderArrowStripCls.substr(1));
                } else {
                    $(publicVars.cssId.sliderArrowLeftId).css({display: 'none'});
                    $(publicVars.cssId.sliderArrowRightId).css({display: 'none'});
                }
            } else {
                if (publicVars.btnInactive === 1) {
                    $(publicVars.cssId.sliderArrowLeftId).addClass(publicVars.cssId.sliderArrowStripCls.substr(1));
                    $(publicVars.cssId.sliderArrowRightId).addClass(publicVars.cssId.sliderArrowStripCls.substr(1));
                } else {
                    $(publicVars.cssId.sliderArrowLeftId).css({display: 'block'});
                    $(publicVars.cssId.sliderArrowRightId).css({display: 'block'});
                }
            }
        },

        calculatePrevNextValues: function (dir) {

            let nextFirstItem;
            let nextLastItem;
            let moveBy;

            if (publicVars.perSetItems === 1) {
                moveBy = publicVars.perSetItems;
            } else {
                moveBy = publicVars.perSetItems - 2;
            }

            let signOut, signIn;

            if (dir === 'left') {
                signOut = '';
                signIn = '-';

                if ((privateVars.sliderCurrentItemFirst - moveBy) > 0) {
                    nextFirstItem = privateVars.sliderCurrentItemFirst - moveBy;
                } else {
                    //var rest = privateVars.sliderCount - privateVars.sliderCurrentItemFirst;
                    nextFirstItem = privateVars.sliderCount - moveBy + privateVars.sliderCurrentItemFirst;
                }

                if ((privateVars.sliderCurrentItemLast - moveBy) > 0) {
                    nextLastItem = privateVars.sliderCurrentItemLast - moveBy;
                } else {
                    nextLastItem = privateVars.sliderCount - moveBy + privateVars.sliderCurrentItemLast;
                }


            } else {
                signOut = '-';
                signIn = '';

                if ((privateVars.sliderCurrentItemFirst + moveBy) <= privateVars.sliderCount) {
                    nextFirstItem = privateVars.sliderCurrentItemFirst + moveBy;
                } else {
                    //var rest = privateVars.sliderCount - privateVars.sliderCurrentItemFirst;
                    nextFirstItem = moveBy + privateVars.sliderCurrentItemFirst - privateVars.sliderCount;
                }

                if ((privateVars.sliderCurrentItemLast + moveBy) <= privateVars.sliderCount) {
                    nextLastItem = privateVars.sliderCurrentItemLast + moveBy;
                } else {
                    //var rest = privateVars.sliderCount - privateVars.sliderCurrentItemFirst;
                    nextLastItem = moveBy + privateVars.sliderCurrentItemLast - privateVars.sliderCount;
                }
            }

            return {nextFirstItem: nextFirstItem, nextLastItem: nextLastItem, signIn: signIn, signOut: signOut}
        },

        putProperData: function (dir, nextFirstItem, nextLastItem) {

            const itemWidth = $(publicVars.cssId.sliderItemCls).outerWidth();

            let i;

            if (dir === 'right' || dir === 'recalculate') {

                let excludeIds = [];

                if (dir !== 'recalculate') {
                    if (publicVars.perSetItems !== 1) {
                        excludeIds.push(nextFirstItem);
                        excludeIds.push(privateVars.sliderCurrentItemLast);
                    }
                }

                $(publicVars.cssId.sliderItemCls).each(function (key, value) {
                    if (excludeIds.indexOf($(value).data('id')) === -1) {
                        $(publicVars.cssId.sliderItemCls + $(value).data('id')).addClass(publicVars.cssId.isObsoleteCls.substr(1));
                    }
                });

                if (nextFirstItem > nextLastItem) {
                    for (i = nextFirstItem; i <= privateVars.sliderCount; i++) {
                        if (privateVars.sliderItemsMap[(i - 1)] && excludeIds.indexOf(i) === -1) {

                            if (typeof privateVars.elemDecorateCallback === 'function') {
                                privateVars.sliderItemsMap[(i - 1)].element = privateVars.elemDecorateCallback(privateVars.sliderItemsMap[(i - 1)].element);
                            }

                            $(publicVars.cssId.sliderSetCls).append(privateVars.sliderItemsMap[(i - 1)].element.clone(true, true));
                        }
                    }

                    for (i = 1; i <= nextLastItem; i++) {
                        if (privateVars.sliderItemsMap[(i - 1)] && excludeIds.indexOf(i) === -1) {

                            if (typeof privateVars.elemDecorateCallback === 'function') {
                                privateVars.sliderItemsMap[(i - 1)].element = privateVars.elemDecorateCallback(privateVars.sliderItemsMap[(i - 1)].element);
                            }

                            $(publicVars.cssId.sliderSetCls).append(privateVars.sliderItemsMap[(i - 1)].element.clone(true, true));
                        }
                    }
                } else {

                    for (i = nextFirstItem; i <= nextLastItem; i++) {

                        if (privateVars.sliderItemsMap[(i - 1)] && excludeIds.indexOf(i) === -1) {
                            if (typeof privateVars.elemDecorateCallback === 'function') {
                                privateVars.sliderItemsMap[(i - 1)].element = privateVars.elemDecorateCallback(privateVars.sliderItemsMap[(i - 1)].element);
                            }

                            $(publicVars.cssId.sliderSetCls).append(privateVars.sliderItemsMap[(i - 1)].element.clone(true, true));
                        }
                    }
                }


            } else {

                let excludeIds = [];

                if (publicVars.perSetItems !== 1) {
                    excludeIds.push(privateVars.sliderCurrentItemFirst);
                    excludeIds.push(nextLastItem);
                }

                $(publicVars.cssId.sliderItemCls).each(function (key, value) {
                    if (excludeIds.indexOf($(value).data('id')) === -1) {
                        $(publicVars.cssId.sliderItemCls + $(value).data('id')).addClass(publicVars.cssId.isObsoleteCls.substr(1));
                    }
                });

                if (publicVars.perSetItems === 1) {
                    excludeIds = [];
                }

                let translateValue;

                if (publicVars.perSetItems === 1) {
                    translateValue = -itemWidth * (publicVars.perSetItems) + 'px, 0'
                } else {
                    translateValue = -itemWidth * (publicVars.perSetItems - 2) + 'px, 0';
                }

                $(publicVars.cssId.sliderSetCls).css({translate: translateValue});

                if (nextFirstItem > nextLastItem) {

                    for (i = nextLastItem; i >= 1; i--) {
                        if (privateVars.sliderItemsMap[(i - 1)] && excludeIds.indexOf(i) === -1) {

                            if (typeof privateVars.elemDecorateCallback === 'function') {
                                privateVars.sliderItemsMap[(i - 1)].element = privateVars.elemDecorateCallback(privateVars.sliderItemsMap[(i - 1)].element);
                            }

                            $(publicVars.cssId.sliderSetCls).prepend(privateVars.sliderItemsMap[(i - 1)].element.clone(true, true));
                        }
                    }

                    for (i = privateVars.sliderCount; i >= nextFirstItem; i--) {
                        if (privateVars.sliderItemsMap[(i - 1)] && excludeIds.indexOf(i) === -1) {

                            if (typeof privateVars.elemDecorateCallback === 'function') {
                                privateVars.sliderItemsMap[(i - 1)].element = privateVars.elemDecorateCallback(privateVars.sliderItemsMap[(i - 1)].element);
                            }

                            $(publicVars.cssId.sliderSetCls).prepend(privateVars.sliderItemsMap[(i - 1)].element.clone(true, true));
                        }
                    }
                } else {
                    for (i = nextLastItem; i >= nextFirstItem; i--) {

                        if (privateVars.sliderItemsMap[(i - 1)] && excludeIds.indexOf(i) === -1) {
                            if (typeof privateVars.elemDecorateCallback === 'function') {
                                privateVars.sliderItemsMap[(i - 1)].element = privateVars.elemDecorateCallback(privateVars.sliderItemsMap[(i - 1)].element);
                            }

                            $(publicVars.cssId.sliderSetCls).prepend(privateVars.sliderItemsMap[(i - 1)].element.clone(true, true));
                        }
                    }
                }
            }

        },

        moveSlider: function (dir) {

            privateVars.touchDiff = 0;
            privateVars.touchStart = 0;

            privateVars.touchDiffY = 0;
            privateVars.touchStartY = 0;


            privateVars.slideStarted = false;

            privateMethods.recalculateSlider();

            container.msg(privateVars.name, 'current first item ' + privateVars.sliderCurrentItemFirst);
            container.msg(privateVars.name, 'current last item ' + privateVars.sliderCurrentItemLast);

            const nextPrevValues = privateMethods.calculatePrevNextValues(dir);

            container.msg(privateVars.name, 'next first item ' + nextPrevValues.nextFirstItem);
            container.msg(privateVars.name, 'next last item ' + nextPrevValues.nextLastItem);

            privateMethods.putProperData(dir, nextPrevValues.nextFirstItem, nextPrevValues.nextLastItem);

            let translateValue = '0, 0';

            if (dir === 'right') {
                if (publicVars.perSetItems === 1) {
                    translateValue = nextPrevValues.signOut + $(publicVars.cssId.sliderItemCls).outerWidth() * (publicVars.perSetItems) + 'px, 0';
                } else {
                    translateValue = nextPrevValues.signOut + $(publicVars.cssId.sliderItemCls).outerWidth() * (publicVars.perSetItems - 2) + 'px, 0';
                }
            }

            privateVars.sliderCurrentItemFirst = nextPrevValues.nextFirstItem;
            privateVars.sliderCurrentItemLast = nextPrevValues.nextLastItem;

            privateMethods.preloadImages();

            $(publicVars.cssId.sliderSetCls).transit({translate: translateValue, rotate: '-0.01deg'}, 500, function () {

                $(publicVars.cssId.isObsoleteCls + publicVars.cssId.sliderItemCls).remove();

                $(publicVars.cssId.sliderSetCls).removeAttr('style');
                $(publicVars.cssId.sliderItemCls).removeAttr('style');

                privateVars.slide = 0;
                privateVars.endTrans = false;

            });


        }
    };

    return {
        init: function (options) {
            privateMethods.prepareObj(options);
        },
        setElementDecorateCallback: function (callback) {
            privateVars.elemDecorateCallback = callback;
        }
    };
};

const factory = {
    objId: 0,
    /**
     * Get slider cut
     *
     */
    create: function () {
        factory.objId++;

        return slider(factory.objId);
    }
};

export default {
    create: factory.create
}


/*

 */
